import "./styles.css";
import { useState } from "react";

export const App = () => {
  const [clicked, setClicked] = useState(false);

  return (
    <div className="page">
      <div className="envelope">
        <div
          className={clicked ? "paper clicked" : "paper"}
          onClick={() => setClicked(true)}
        >
          <div className="placeholder" />
          <div className="content">
            <div className="section">Dear Sonkipipo,</div>
            <div className="section">
              I wish you a very Happy Birthday, Happy Birthday-Week, Happy
              Birthday-Month, and Happy Birthday-Year.
            </div>
            <div className="section">
              May all your wishes come true, may the Sunshine Blueberries grow
              particularly well, and may the radio play not a single song by
              Taylor Swift!
            </div>
            <div className="present">
              <a href="/diggers.pdf" target="_blank" rel="noreferrer">
                <img
                  src="https://twemoji.maxcdn.com/v/12.1.5/svg/1f381.svg"
                  height="14px"
                  width="14px"
                  alt="Sonki's present"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
